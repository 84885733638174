import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 8 ) should always be at the top of the file we use them for price strings.
   */
  {
    pid: 0,
    lpSymbol: '2333',
    lpAddresses: {
      97: '',
      56: '0x106a81383db26aa7849b6d59b3858b2dff7a9c8f',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: '2333-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x18678f7472178e98cd82610c3c584d887f9f8625',
    },
    token: tokens.cake,
    quoteToken: tokens.usdt,
  },
   
  
  
 ]

 export default farms
